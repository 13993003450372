import { PersonalAccount, PersonalAccountProfile } from "@zonevs/core/types";
import axios from "axios";

export function FormatProfileName(profile?: PersonalAccountProfile) {
  if (!profile) return "";
  return `${profile?.firstName} ${profile?.lastName}`;
}

export async function GetPersonalAccount() {
  const result = await axios({
    method: "GET",
    url: `/api/proxy/private/account`
  });

  return result.data.account as PersonalAccount;
}

export interface UpdatePersonalAccountProfileProps {
  profile: PersonalAccountProfile;
}

export async function UpdatePersonalAccount(
  params: UpdatePersonalAccountProfileProps
) {
  await axios({
    method: "PUT",
    url: `/api/proxy/private/account/profile`,
    data: {
      profile: params.profile
    }
  });
}
