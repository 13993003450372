import SpaceBasicCorporate01 from "@/lib/rooms/space-basic-corporate-01";
import SpaceBasicHarry01 from "@/lib/rooms/space-basic-harry-01";
import { SpaceDefinitionConfig } from "@/lib/rooms/base-types";
import SpaceEmpty01 from "@/lib/rooms/space-empty-01";

// add new rooms to this list
export const rooms: SpaceDefinitionConfig[] = [
  SpaceEmpty01,
  SpaceBasicCorporate01,
  SpaceBasicHarry01
];
