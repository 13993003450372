import { useMutation, useQueryClient } from "react-query";
import { CreateSpace, CreateSpaceParams } from "@/lib/space-lib";
import { Space } from "@zonevs/core/types";

export default function useCreateSpace() {
  const queryClient = useQueryClient();

  const result = useMutation(
    (params: CreateSpaceParams) => CreateSpace(params),
    {
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
        queryClient.invalidateQueries("spaces");
      },
    }
  );

  return {
    ...result,
    app: result?.data?.app as Space,
  };
}
