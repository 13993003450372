import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { useUser } from "@auth0/nextjs-auth0/client";
import { Stack, useMediaQuery } from "@mui/material";
import Image from "next/image";
import Link from "./common/Link";

const pages = ["Products", "Pricing", "Blog"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function NavBar() {
  const { user } = useUser();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="relative">
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box
            width={100}
            height={50}
            position="relative"
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            {/*<Image*/}
            {/*  src="/images/zonevs-logo.png"*/}
            {/*  alt="zonevs logo"*/}
            {/*  fill*/}
            {/*  style={{ objectFit: "contain" }}*/}
            {/*/>*/}
            <a href="https://zonevs.io">
              <Image
                src="/images/zonevs-logo.png"
                alt="zonevs logo"
                fill
                style={{ objectFit: "contain" }}
              />
            </a>
          </Box>
          <Stack
            justifyContent="center"
            sx={{ flexGrow: 1 }}
            direction="row"
            spacing={6}
          >
            {/*<Link href="/">Dashboard</Link>*/}
            {/*<Link href="https://google.com">Spaces</Link>*/}
            {/*<Link href="/team">Team</Link>*/}
            {/*<Link href="https://next-saas-starter-delta.vercel.app/about">About</Link>*/}
          </Stack>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/*<Box width={100} height={50} position='relative'>*/}
          {/*  <Image src='/images/zonevs-logo.png' alt='zonevs logo' fill style={{objectFit: "contain"}}/>*/}
          {/*</Box>*/}
          <Box sx={{ display: { xs: "none", md: "flex" } }}></Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={user?.name || "avatar"}
                  src={user?.picture || undefined}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                onClick={() => window.open("/api/auth/logout", "_self")}
              >
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavBar;
