import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import useAccount from "@/hooks/account/useAccount";
import useUpdateAccountProfile from "@/hooks/account/useUpdateAccountProfile";
import { useUser } from "@auth0/nextjs-auth0/client";

interface WelcomeDialogProps {}
const WelcomeDialog: FunctionComponent<WelcomeDialogProps> = (props) => {
  const { account } = useAccount();
  const { user } = useUser();

  const updateProfile = useUpdateAccountProfile();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(account && !account.profile);
  }, [account]);

  const onClose = () => {
    setOpen(false);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  useEffect(() => {
    if (user && formData.email === "") {
      setFormData({
        ...formData,
        email: user.email || "",
      });
    }
  }, [user, formData]);

  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    let errors = false;
    const newErrors = { ...formErrors };

    // Simple form validation example (can be enhanced as needed)
    if (formData.firstName.trim() === "") {
      newErrors.firstName = true;
      errors = true;
    } else {
      newErrors.firstName = false;
    }

    if (formData.lastName.trim() === "") {
      newErrors.lastName = true;
      errors = true;
    } else {
      newErrors.lastName = false;
    }

    if (
      formData.email.trim() === "" ||
      !formData.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    ) {
      newErrors.email = true;
      errors = true;
    } else {
      newErrors.email = false;
    }

    if (errors) {
      setFormErrors(newErrors);
    } else {
      // Perform action with the form data (e.g., submit the data)
      console.log("Form submitted:", formData);

      await updateProfile.mutateAsync({
        profile: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
        },
      });

      // Close the dialog
      onClose();
    }
  };

  const handleClose = () => {
    onClose();
    // Reset form and errors when dialog is closed
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
    });
    setFormErrors({
      firstName: false,
      lastName: false,
      email: false,
    });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Welcome!</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            margin="dense"
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            error={formErrors.firstName}
            helperText={formErrors.firstName ? "First Name is required" : ""}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            error={formErrors.lastName}
            helperText={formErrors.lastName ? "Last Name is required" : ""}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            error={formErrors.email}
            helperText={
              formErrors.email ? "Please enter a valid email address" : ""
            }
            fullWidth
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={updateProfile.isLoading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WelcomeDialog;
