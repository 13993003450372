import React, { FunctionComponent } from "react";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import useSpaces from "@/hooks/spaces/useSpaces";
import SpaceListItem from "@/components/SpaceListItem";

interface SpaceListProps {}

const SpaceList: FunctionComponent<SpaceListProps> = (props) => {
  const { spaces } = useSpaces();

  return (
    <Box
      flexGrow={1}
      sx={{
        maxHeight: "100%",
        overflowY: "auto",
      }}
    >
      <Stack spacing={2}>
        {spaces?.map((space) => (
          <SpaceListItem space={space} key={space._id} />
        ))}
      </Stack>
    </Box>
  );
};

export default SpaceList;
