import React, { FunctionComponent, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import useCreateSpace from "@/hooks/spaces/useCreateSpace";
import { rooms } from "@/lib/rooms";


interface CreateSpaceDialogProps extends DialogProps {
  onCreate: Function;
  onCancel: Function;
}

const CreateSpaceDialog: FunctionComponent<CreateSpaceDialogProps> = (
  props
) => {
  const createSpace = useCreateSpace();

  const [name, setName] = useState("");
  const [room, setRoom] = useState(rooms[0].id);

  const reset = () => {
    setName("");
    setRoom(rooms[0].id);
  };
  const handleCreateSpace = async () => {
    await createSpace.mutateAsync({
      name,
      spaceDefinitionId: room
    });
    props.onCreate();
    reset();
  };

  const handleCancel = () => {
    props.onCancel();
    reset();
  };

  return (
    <Dialog maxWidth="sm" fullWidth {...props}>
      <DialogTitle>Create a new space</DialogTitle>
      <DialogContent>
        <Stack spacing={1} sx={{ my: 2 }}>
          <TextField
            value={name}
            onChange={(event) => setName(event?.target.value)}
            fullWidth
            label="Name"
          />
          <Select
            value={room}
            onChange={(event) => setRoom(event?.target.value)}
          >
            {rooms.map((room) => (
              <MenuItem key={room.id} value={room.id}>
                {room.name}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={createSpace.isLoading} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={createSpace.isLoading || name === ""}
          onClick={handleCreateSpace}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSpaceDialog;
