import React, { FunctionComponent, PropsWithChildren } from "react";
import { Paper } from "@mui/material";

interface PaperTileProps {}

const PaperTile: FunctionComponent<PropsWithChildren<PaperTileProps>> = (
  props
) => {
  return (
    <Paper
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        p: 4,
        borderRadius: 3,
        boxShadow: "1px 1px 10px 0px rgba(0,0,0,0.1)",
      }}
    >
      {props.children}
    </Paper>
  );
};
export default PaperTile;
