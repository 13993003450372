import axios from "axios";
import { SpaceDefinitionOverrides } from "@zonevs/core/types";

export async function ListSpaces() {
  const result = await axios({
    method: "GET",
    url: "/api/proxy/private/spaces",
  });

  return result.data;
}

export interface JoinSpaceParams {
  spaceId: string;
}

export async function JoinSpace(params: JoinSpaceParams) {
  const result = await axios({
    method: "GET",
    url: `/api/proxy/public/spaces/${params.spaceId}/join`,
  });

  return result.data;
}

export interface GetSpaceParams {
  spaceId: string;
}

export async function GetSpace(params: GetSpaceParams) {
  const result = await axios({
    method: "GET",
    url: `/api/proxy/private/spaces/${params.spaceId}`,
  });

  return result.data;
}

export interface CreateSpaceParams {
  name: string;
  spaceDefinitionId?: string;
}

export async function CreateSpace(params: CreateSpaceParams) {
  const result = await axios({
    method: "POST",
    url: "/api/proxy/private/spaces",
    data: params,
  });

  return result.data;
}

export interface DeleteSpaceParams {
  spaceId: string;
}

export async function DeleteSpace(params: DeleteSpaceParams) {
  await axios({
    method: "DELETE",
    url: `/api/proxy/private/spaces/${params.spaceId}`,
  });
}

export interface UpdateSpaceParams {
  spaceId: string;
  overrides: SpaceDefinitionOverrides;
}

export async function UpdateSpace(params: UpdateSpaceParams) {
  await axios({
    method: "PUT",
    url: `/api/proxy/private/spaces/${params.spaceId}`,
    data: {
      overrides: params.overrides,
    },
  });
}

export interface UploadContentParams {
  spaceId: string;
  mimeType: string;
  file: File;
}

export async function UploadContent(params: UploadContentParams) {
  const result = await axios({
    method: "POST",
    url: `/api/proxy/private/spaces/${params.spaceId}/content/upload`,
    data: {
      mimeType: params.mimeType,
    },
  });

  const { uploadUrl } = result.data;

  await axios({
    method: "PUT",
    url: uploadUrl,
    data: params.file,
  });

  return result.data;
}

export function generateBroadcasterSlotChannel(
  baseChannel: string,
  slotId: string
) {
  return `${baseChannel}-broadcast-${slotId}`;
}
