import { useQuery } from "react-query";
import { ListSpaces } from "@/lib/space-lib";
import { Space } from "@zonevs/core/types";

export default function useSpaces() {
  const result = useQuery("spaces", () => ListSpaces());
  return {
    ...result,
    spaces: result?.data?.spaces as Space[],
  };
}
