import Head from "next/head";
import { withPageAuthRequired } from "@auth0/nextjs-auth0/client";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import NavBar from "@/components/NavBar";
import CreateSpaceDialog from "@/components/CreateSpaceDialog";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import theme from "@/lib/theme";
import SpaceList from "@/components/SpaceList";
import PaperTile from "@/components/common/PaperTile";
import useSpaces from "@/hooks/spaces/useSpaces";
import { Add } from "@mui/icons-material";
import useAccount from "@/hooks/account/useAccount";
import { CreateCheckoutSession, CreatePortalSession } from "@/lib/billing-lib";
import WelcomeDialog from "@/components/WelcomeDialog";
import LoadingScreen from "@/components/LoadingScreen";

const drawerWidth = 350;

function Home() {
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [createSpaceOpen, setCreateSpaceOpen] = useState(false);

  const { spaces, isLoading: isLoadingSpaces } = useSpaces();

  const { account } = useAccount();

  const [loadingBilling, setLoadingBilling] = useState(false);

  const createCheckoutSession = async () => {
    setLoadingBilling(true);
    try {
      const url = await CreateCheckoutSession();
      window.open(url, "_self");
    } catch (e) {}
    setLoadingBilling(false);
  };

  const createPortalSession = async () => {
    setLoadingBilling(true);
    try {
      const url = await CreatePortalSession();
      window.open(url, "_self");
    } catch (e) {}
    setLoadingBilling(false);
  };

  // const isPremium = account?.subscription;
  // const spaceLimit = isPremium ? 999 : 3;
  // const canCreateSpace = spaces?.length < spaceLimit;

  return (
    <>
      <Head>
        <title>ZoneVS | Spaces</title>
        <meta name="description" content="Dashboard | That Open Platform" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <CreateSpaceDialog
        open={createSpaceOpen}
        onCancel={() => setCreateSpaceOpen(false)}
        onCreate={() => setCreateSpaceOpen(false)}
      />

      <WelcomeDialog />

      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
        }}
      >
        <NavBar />
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Box py={5}>
            <Typography variant="h4">Dashboard</Typography>
          </Box>
          <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            <Grid
              item
              xs={12}
              md={8}
              sx={{ display: "flex", flexGrow: 1, overflow: "hidden" }}
            >
              <PaperTile>
                <Box sx={{ display: "flex" }}>
                  {/*<Typography variant="h6" sx={{ mb: 4, flexGrow: 1 }}>*/}
                  {/*  My Spaces{" "}*/}
                  {/*  {!isPremium &&*/}
                  {/*    spaces &&*/}
                  {/*    `(${spaces?.length || 0} / ${spaceLimit})`}*/}
                  {/*</Typography>*/}
                  <div>
                    <Button
                      sx={{ flexGrow: 0 }}
                      size="small"
                      onClick={() => setCreateSpaceOpen(true)}
                      startIcon={<Add />}
                      // disabled={!canCreateSpace}
                    >
                      New Space
                    </Button>
                  </div>
                </Box>

                {isLoadingSpaces && (
                  <Box
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      flexGrow: 1,
                      display: "flex",
                    }}
                  >
                    <Stack spacing={3} alignItems={"center"}>
                      <CircularProgress />
                    </Stack>
                  </Box>
                )}
                {!isLoadingSpaces && spaces.length === 0 && (
                  <Box
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      flexGrow: 1,
                      display: "flex",
                    }}
                  >
                    <Stack spacing={3} alignItems={"center"}>
                      <Typography align="center">
                        {"It looks like you don't have any spaces yet."}
                        <br />
                        {"Create a space to get started."}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={() => setCreateSpaceOpen(true)}
                      >
                        CREATE SPACE
                      </Button>
                    </Stack>
                  </Box>
                )}

                <Box sx={{ maxHeight: "65vh", overflowY: "auto" }}>
                  <SpaceList />
                </Box>
              </PaperTile>
            </Grid>
            {/*<Grid item xs={12} md={4} sx={{ display: "flex", flexGrow: 1 }}>*/}
            {/*  <Grid container spacing={2} sx={{ flexGrow: 1 }}>*/}
            {/*    <Grid item xs={12} sx={{ display: "flex", flexGrow: 1 }}>*/}
            {/*      <PaperTile>*/}
            {/*        <Stack spacing={2} justifyContent="space-between">*/}
            {/*          <Typography variant="h6">My Account</Typography>*/}

            {/*          {account && !account?.subscription && (*/}
            {/*            <Stack spacing={2}>*/}
            {/*              <Typography align="center" variant="body1">*/}
            {/*                Upgrade your account for unlimited access*/}
            {/*              </Typography>*/}
            {/*              <Button*/}
            {/*                color="primary"*/}
            {/*                variant="contained"*/}
            {/*                disabled={loadingBilling}*/}
            {/*                onClick={createCheckoutSession}*/}
            {/*              >*/}
            {/*                Upgrade now*/}
            {/*              </Button>*/}
            {/*            </Stack>*/}
            {/*          )}*/}

            {/*          {account && account.subscription && (*/}
            {/*            <Stack spacing={2}>*/}
            {/*              <Typography align="center" variant="h4">*/}
            {/*                {"You're premium"}*/}
            {/*              </Typography>*/}
            {/*            </Stack>*/}
            {/*          )}*/}

            {/*          <Button*/}
            {/*            disabled={loadingBilling}*/}
            {/*            onClick={createPortalSession}*/}
            {/*          >*/}
            {/*            Manage Billing*/}
            {/*          </Button>*/}
            {/*        </Stack>*/}
            {/*      </PaperTile>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12} sx={{ display: "flex", flexGrow: 1 }}>*/}
            {/*      <PaperTile />*/}
            {/*    </Grid>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
          </Grid>
        </Container>
        <Toolbar />
      </Box>
    </>
  );
}

export default withPageAuthRequired(Home);
