import axios from "axios";

export async function CreateCheckoutSession() {
  const result = await axios({
    method: "POST",
    url: `/api/proxy/private/billing/checkout`,
  });

  return result.data.url as string;
}

export async function CreatePortalSession() {
  const result = await axios({
    method: "POST",
    url: `/api/proxy/private/billing/portal`,
  });

  return result.data.url as string;
}
