import { useQuery } from "react-query";
import { GetPersonalAccount } from "@/lib/account-lib";
import { PersonalAccount } from "@zonevs/core/types";

export default function useAccount() {
  const query = useQuery("account", () => GetPersonalAccount(), {
    cacheTime: 1000 * 30,
    staleTime: 1000 * 30,
  });

  return {
    ...query,
    account: query.data as PersonalAccount,
  };
}
