import React, { FunctionComponent } from "react";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import useSpaces from "@/hooks/spaces/useSpaces";
import { Space } from "@zonevs/core/types";
import useDeleteSpace from "@/hooks/spaces/useDeleteSpace";
import { useRouter } from "next/router";

interface SpaceListProps {
  space: Space;
}

const SpaceListItem: FunctionComponent<SpaceListProps> = (props) => {
  const { space } = props;

  const deleteSpace = useDeleteSpace();

  const router = useRouter();
  const handleDelete = () => {
    deleteSpace.mutateAsync({ spaceId: space._id });
  };

  const handleConfigurator = async () => {
    await router.push(`/configurator/${space._id}`);
  };

  const handleBroadcaster = async () => {
    await router.push(`/broadcaster/${space._id}`);
  };

  const handleJoin = async () => {
    await router.push(`/s/${space._id}`);
  };

  return (
    <Paper sx={{ p: 3 }} variant="outlined">
      <Stack spacing={1}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography variant="subtitle1">{space.name}</Typography>
          <Box flexGrow={1} />
          <Typography variant="caption" color="textSecondary">
            ID: {space._id}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography variant="caption">
            Created on: {new Date(space.createdAt).toLocaleDateString()}
          </Typography>
          <Box flexGrow={1} />
          <Stack direction="row" spacing={1}>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={handleJoin}
            >
              Join
            </Button>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={handleConfigurator}
            >
              Configure
            </Button>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={handleBroadcaster}
            >
              Broadcaster
            </Button>
            <Button
              color="error"
              variant="outlined"
              size="small"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
};

export default SpaceListItem;
