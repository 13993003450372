import { SpaceDefinitionConfig } from "@/lib/rooms/base-types";
import { Vector3 } from "three";

const config: SpaceDefinitionConfig = {
  id: "empty-01",
  name: "Empty 01",
  description: "Empty Room",
  teleport_pads: [
    {
      id: "pad-1",
      modelUrl: "/models/teleport-pad.glb",
      position: new Vector3(-10, 0, -10)
    },
    {
      id: "pad-2",
      modelUrl: "/models/teleport-pad.glb",
      position: new Vector3(10, 0, 10)
    },
    {
      id: "pad-3",
      modelUrl: "/models/teleport-pad.glb",
      position: new Vector3(10, 0, -10)
    },
    {
      id: "pad-4",
      modelUrl: "/models/teleport-pad.glb",
      position: new Vector3(-10, 0, 10)
    }
  ],
  screen_share_slots: [],
  broadcast_slots: [],
  branding_slots: [],
  // branding_slots: [
  //
  //   {
  //     id: "Hex Floor 1",
  //     type: "image",
  //     objectId: "hex-display-1",
  //     materialId: "branding-slot-1",
  //     configuratorCameraPosition: new Vector3(
  //       0, 2, 0
  //     )
  //   },
  //   {
  //     id: "Hex Floor 2",
  //     type: "video",
  //     objectId: "hex-display-2",
  //     materialId: "branding-slot-1",
  //     configuratorCameraPosition: new Vector3(
  //       0, 2, 0
  //     )
  //   },
  //   {
  //     id: "Curved Slot 1",
  //     type: "video",
  //     objectId: "curved-screen-1",
  //     materialId: "branding-slot-1",
  //     configuratorCameraPosition: new Vector3(
  //       10, 2, 0
  //     )
  //   },
  //   {
  //     id: "Curved Slot 2",
  //     type: "video",
  //     objectId: "curved-screen-2",
  //     materialId: "branding-slot-1",
  //     configuratorCameraPosition: new Vector3(
  //       0, 2, 0
  //     )
  //   },
  //   {
  //     id: "Curved Slot 3",
  //     type: "image",
  //     objectId: "curved-screen-3",
  //     materialId: "branding-slot-1",
  //     configuratorCameraPosition: new Vector3(
  //       0, 2, 0
  //     )
  //   },
  //   {
  //     id: "Curved Slot 4",
  //     type: "image",
  //     objectId: "curved-screen-4",
  //     materialId: "branding-slot-1",
  //     configuratorCameraPosition: new Vector3(
  //       0, 2, 0
  //     )
  //   }
  //
  // ],
  assets: [
    // {
    //   id: "curved-screen-1",
    //   url: "/models/curved-screen-display.glb",
    //   type: "gltf"
    // },
    // {
    //   id: "curved-screen-2",
    //   url: "/models/curved-screen-display.glb",
    //   type: "gltf",
    //   rotation: new Vector3(0, -90, 0)
    // },
    // {
    //   id: "curved-screen-3",
    //   url: "/models/curved-screen-display.glb",
    //   type: "gltf",
    //   rotation: new Vector3(0, -180, 0)
    // },
    // {
    //   id: "curved-screen-4",
    //   url: "/models/curved-screen-display.glb",
    //   type: "gltf",
    //   rotation: new Vector3(0, 90, 0)
    // },
    {
      id: "hex-display-1",
      url: "/models/hex-screen-display.glb",
      type: "gltf",
      position: new Vector3(-5, 0, 5),
      rotation: new Vector3(-90, 0, 0)
    },
    {
      id: "hex-display-2",
      url: "/models/hex-screen-display.glb",
      type: "gltf",
      position: new Vector3(5, 0, 10),
      rotation: new Vector3(-90, 0, 0)
    }
  ]
};

export default config;
